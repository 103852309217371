.filter-dropdown{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    max-height: 40px;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #EAEAEC;
    border-radius: 6px;
    cursor: pointer;
}

.menu_btn{
    background: none;
    border: none;
    cursor: pointer;
}