.ant-collapse-header{
    color:black;
    font-weight: bolder;
    font-size: 15px;
    background-color: white !important;
    border:none !important;
    padding:30px !important;
}
.ant-collapse {
    border:none !important;
}