$primary_color: "rgba(255, 255, 255, 0.5)";
$active_color: "rgba(255, 255, 255, 1)";

$active_menu_background: "rgba(0, 0, 0, 0.12)";
$active_submenu_background: "rgba(255, 255, 255, 0.03)";

.subMenuActive {
  color: $active_color;
  background: rgba(255, 255, 255, 0.03);
  :first-child {
    margin-top: 0px !important;
  }
}
.subMenu {
  color: $primary_color;
  :first-child {
    margin-top: 0px !important;
  }
}

.menu {
  color: $primary_color;
  background-color: transparent;
}
.menu-active {
  color: $active_color;
}

div::-webkit-scrollbar-thumb {
  /* Set the color of the scrollbar thumb */
  background-color: rgba(0, 0, 0, 0.5);
  /* Set the border radius of the scrollbar thumb */
  border-radius: 5px;
}

div::-moz-scrollbar-thumb {
  /* Set the color of the scrollbar thumb */
  background-color: rgba(0, 0, 0, 0.5);
  /* Set the border radius of the scrollbar thumb */
  border-radius: 5px;
}

.connected_accounts::-webkit-scrollbar {
  display: none !important;
}
