.grid-item {
    border: 1px solid #EFEFEF;
    border-radius: 12px;
    box-shadow: 0px 4.83018px 16.9056px rgba(0, 0, 0, 0.05);
    padding: 20px
}

.displayRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.displayCol {
    gap: 0.2rem;
    display: flex;
    flex-direction: column;
}

.displayCol span {
    word-wrap: break-word;
}

.pagination {
    /* border-top: 1px solid rgba(224, 224, 224, 1); */
    padding: 10px !important;
    .MuiTablePagination-selectLabel, 
    .MuiTablePagination-displayedRows, 
    .MuiInputBase-root {
        background-color: transparent;
        color: #585969 !important;
        font-family: Inter !important;
        font-size: 13px !important;
    }
}

.pagination p {
    margin: auto;
}

.trigger-table::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}

.trigger-table th {
    color: #9798A1;
    background: #F8F9FD;
    /* text-transform: uppercase; */
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    border-bottom: none;
}

.trigger-table td {
    font-family: 'Inter';
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #333333;
    border-bottom: none;
    background: #FFFFFF;
}

.trigger-table td button {
    /* background: #F3F8FF; */
    background: #FAFAFA;
    margin-right: 10px;
    border-radius: 6px;
}

.greyText {
    color: #5F6771;
    font-size: .75rem;
    font-style: normal;
    font-family: Inter;
}

.statusText {
    padding: 0.3125rem 0.75rem;
    font-size: .75rem;
    border-radius: 1.0625rem;
    background-color: var(--bg-color);
}

.Successfull {
    color: #1ABFA1 !important;
    --bg-color: rgba(26, 191, 161, 0.1);
}

.DONE {
    color: #1ABFA1 !important;
    --bg-color: rgba(26, 191, 161, 0.1);
}

.Failed {
    color: #E84B47 !important;
    --bg-color: rgba(232, 75, 71, 0.1);
}

.FAILED {
    color: #E84B47 !important;
    --bg-color: rgba(232, 75, 71, 0.1);
}

.Running {
    color: #e8b547 !important;
    --bg-color: rgba(232, 194, 71, 0.1);
}

.remove-trigger-modal {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #ffff;
    width: 33%;
    padding: 1px;
    z-index: 9000000000000;
    position: absolute;
    border-radius: 8px;
}

.remove-trigger-modal>.header {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #eee;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
}

.remove-trigger-modal>.content {
    height: calc(100% - 50px);
    padding: 10px 20px;
    font-size: 16px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
    padding: 10px 20px;
}

.header button {
    border-radius: 8px;
    text-transform: none;
}

.footer {
    border-top: 1px solid #eee;
    padding: 15px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.footer button {
    border-radius: 8px;
    text-transform: none;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
}

.chip {
    background: #d9d9d9aa;
    font-size: 14px;
    margin: 5px;
    padding: 2px 10px;
    width: fit-content;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.trigger-form h3 {
    margin: auto 0px;
    color: #1A283E;
    font-weight: 500;
}

.trigger-form h4 {
    margin: auto 0px;
    color: #5F6771;
    font-weight: 500;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.email {
    gap: 0.5rem;
    height: 100%;
    display: flex;
    font-style: normal;
    font-size: 12px;
    align-items: center;
    font-family: Inter;
}

.loader {
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chipBlock::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}

.chipBlock {
    overflow: scroll;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    width: "100%";
}

.chipBlock>div {
    max-width: fit-content !important;
}

.scrollList::-webkit-scrollbar {
    width: 10px !important;
}