
.containerShopify h1, h2, h3, h4, h5, h6 {
    line-height: 1.24;
    font-weight: 400;
    color: #3a3c4c;
}
.containerShopify{
    width:100vw;
    min-height: 100vh;
    background-color: #F2F5F7;
}
.containerShopify .header{
    padding-left: 16px;
    padding-right: 16px;
    height: 80px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
    background-color: #ffff;
    width:100vw;
    line-height: 70px;
    margin-bottom: 20px;
}
.containerShopify .header nav{
    display: flex;
    max-width: 400px;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
}
.logoText{
    padding:10px;
    transform: translateY(10px);
}
.containerShopify .main{
    width:100vw;
    min-height: 100vh;
    background-color: #F2F5F7;
}
.containerShopify .contents{
    width:60vw;
    min-height: 100vh !important;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    background-color: #ffffff;
}
.containerShopify .contents > div{
    margin: 10px;
}
.containerShopify .main-heading h1{
    font-size: 34px;

}
.containerShopify .article{
    font-size: 20px;
    line-height: 1.35;
    margin-bottom: 17px;
    color: #8F919D;
}
.containerShopify .article p{
    font-size: 17px;
    margin-top: 20px;
}
.containerShopify .articleDescription p{
    min-height: 1.53em;
    color: #565867;
    margin-top: 20px;
    line-height: 1.26;
}
.containerShopify .imgContainer{
    width: 100%;
    
}
.containerShopify img{
    width:100%;
    height: 100%;
}
.containerShopify .shopUrl{
    padding-top: 40px;
    font-weight: bolder;
}
.containerShopify .shopUrl h3{
    font-size: 22px;
}
.containerShopify .shopUrl p{
    margin-bottom: 0;
    min-height: 1.53em;
    line-height: 1.53;
    font-weight: lighter;
}
.containerShopify ol{
    margin-left: 30px;
}
.containerShopify ol li{
    margin:35px 10px;
}
.endArticel{
    background-color: #F0F3F5;
    padding: 40px 20px;
    margin:20px auto;
    text-align: center;
    border-radius: 15px;
}
.emoji{
    display: flex;
    justify-content: center;
    align-items: center;
}
.emoji p{
    width:18px;
    height: 18px;
    font-size: 25px;
    margin: 5px;
}
#scopesList{
    transform: translateX(-20px);
}
#scopesList li{
    margin-top: 0px !important;
    margin-bottom: 5px !important;
}