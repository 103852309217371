#col_chart_html_tooltip span{
  font-family: "Inter";
}

.google-visualization-tooltip {
  border: solid 2px transparent !important;
  border-radius: 10px !important;
  background-color: white;
  position: absolute !important;
  box-shadow: 0px 0px 17px 3px rgba(159, 159, 159, 0.5) !important;
  font-size: 11px;
  font-family: arial;
  min-width: 250px !important;
  max-width: 60%;
  max-height: 80%;
  text-align: left !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  font-family: "Inter";
  overflow-y: auto;
}

.google-visualization-tooltip::-webkit-scrollbar {
  width: 0 !important
}

.google-visualization-tooltip div {
  padding: 0px !important;
  margin: 0px !important;
}

/* Can be removed  */
.tippy-box{
  background-color: #ffff !important;
  box-shadow: 0px 0px 17px 3px rgba(159, 159, 159, 0.5) !important;
  color:black !important;
  border-radius: 10px !important;
}

.tippy-content {
  padding: 10px;
  font-size: 12px;
  font-family: 'Inter';
}

.tippy-arrow {
  display: none;
}
/* Till here! */

.canvasjs-chart-credit{
    display: none !important;
    min-width: 160px;
}
.containerTreemap{
    padding: 16px 10px !important;
    min-width: 200px !important;
    background-color: #FFFF;
    border-radius: 12px;
    z-index: 9999999999 !important;
    min-height: 60px !important;
    overflow: hidden !important;
    box-sizing: border-box !important;
    border: solid 2px #496DFF !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
  }
  .containerTreemap .title{
    font-weight: bold;
  }
  .containerTreemap .tooltip-item{
    width: 100%;
    box-sizing: border-box !important;
    font-family: "Inter";
    font-size: 11px !important;
    font-weight: bolder !important;
    text-align: left;
  }

  .account-footer {
    margin: auto 12px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #939393;
    max-width: 50%;
  }

  .link-on-hover:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .chartBlock > div {
    height: 100%;
    width: 100%;
    /* overflow: scroll !important; */
  }

  .chartBlock > div::-webkit-scrollbar {
    width: 0 !important;
    height:0 !important;
  }

  /* .chartBlock .highcharts-legend {
    position: relative;
  } */

  .chartBlock .highcharts-legend-title {
    /* position: absolute;
    top: 50%;
    right: -20px; Adjust this value as needed */
    /* transform: translateY(22px)translateX(-100px); */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}