.calanderStyling{
    border:none;
}

.calanderModal{
    width: 60%;
    min-height:493px;
    max-height: 510px;
}

@media only screen and (max-width: 1200px) {
    .calanderModal{
        width: 80%;
        height: 60%;
    }
}

@media only screen and (max-width: 800px) {
    .calanderModal{
        width: 80%;
        height: 60%;
    }
}

@media only screen and (max-width: 930px) {
    .calanderModal{
        width: 90%;
        height: 60%;
    }
}

.react-calendar__viewContainer abbr{
    text-decoration: none;
    text-transform: capitalize;
}

.rmdp-wrapper{
    width: 100% !important;
}

.rmdp-shadow{
    box-shadow: none !important;
}

.rmdp-header-values span{
    color:#2E3044 !important;
    font-size: 18px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
}

.rmdp-week-day{
    color: #ABACB4 !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.rmdp-day{
    color: #2E3044 !important;
    box-shadow: none !important;
    font-family: 'Inter';
    font-style: normal;
    width: 15% !important;
}

.rmdp-calendar {
    height: max-content;
    padding: 4px;
    width: 100%;
}

.rmdp-day-picker > div{
    margin-left: 20px;
    width:45%
}

.rmdp-day-picker > div::after{
    content: "";
    display: inline-block;
    width: 1px;
    height: 300px;
    background-color: #EAEAEC;
    position: absolute !important;
    left: 50%;
    top:-45px;
}
.rmdp-year-picker,.rmdp-month-picker{
    box-shadow: 0 0 1px #8798ad;;
}
.rmdp-disabled{
    color: #C0C1C7 !important;
}

.calanderInputNumber input{
    padding: 4px 10px !important;
}

.calanderModal input[type=number]::-webkit-inner-spin-button, 
.calanderModal input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.rmdp-arrow-container{
    border: 1.5px solid #6D6E7C;
    width: 25px !important;
    height: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px !important;
}
.rmdp-arrow{
    margin-top: 0px !important;
}
.compared .rmdp-range {
    background-color:#FEF5F2 !important;
}
.rmdp-range {
    background-color:#cce2ff !important;
}
.rmdp-disabled{
    color:#C0C1C7 !important;
}

.compared .start,.compared .end{
    background-color: #F0967D !important;
 }
.start,.end{
   background-color: #0769FB !important;
}
.start span,.end span{
    color:#FFFF;
}
.rmdp-today{
    background-color: #7fdbff !important;
}

.list::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
}