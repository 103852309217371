.container {
    display: flex;
    margin-top: 10px;
width: 100%;
flex-direction: column;
}

.header {
    display: flex;
    width: 100%;
    align-items: center;
    
}

.header-title {
    margin: 0;    
    color:  rgb(73, 73, 73);   
}

.materialui-daterange-picker-MuiPaper-rounded-8{
    height: 250px !important;
    position: absolute !important;
    transform: translateY(155px) !important;
    z-index: 99999999;
    border:1px solid #eee;
}
.materialui-daterange-picker-MuiPaper-rounded-8{
    height: 250px !important;
    position: absolute !important;
    transform: translateY(155px) !important;
    z-index: 99999999;
    border:1px solid #eee;
}
.materialui-daterange-picker-MuiList-padding-258{
    min-width: 120px !important;
}
