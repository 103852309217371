.Top_header{
    display: flex;
    width: 100%;
    margin-top: 16px;
    gap: 16px;
  }
  .formula_container{
    display: flex;
    width: 100%;
    border: 1px solid #EAEAEC;
    border-radius: 6px;
    height: 30.1vh;
  }
  .formula_buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 8px 24px;
    border: none;
    cursor: pointer;
    color: #5C6674;
    font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  }