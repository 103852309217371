@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

@font-face {
  font-family: "Inter";
  src: url("Assets/Fonts/Inter-Regular.woff") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white m !important;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body > #root > div {
}
.email-input {
  width: 100%;
  height: 40px;
  padding: 16px 12px;
  border: 1px solid #e0e3e5;
  border-radius: 6px;
  margin-top: 10px;
}
.tag-item {
  background-color: #fff;
  // display: inline-block;
  border: 1px solid #eaeaec;
  font-family: Inter;
  font-size: 12px;
  border-radius: 6px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  .button {
    background-color: white;
    width: 12px;
    height: 12px;
    // border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.button0 {
  background-color: #fff; /* Green */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #d9dce0;
  color: #3c4043;
  font-size: 13.6px;
  font-weight: 500;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button1:hover {
  background-color: #f7fafe;
  color: #3c4043;
  border: 1px solid #d7e5fd;
}
.logout:hover .log {
  stroke: #fff !important;
}

.accnt_pro:hover {
  color: #fff !important;
}

.action_button {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;

  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: rgb(132, 124, 196);
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.flexible-box {
  background-color: white;
  padding: 10px 15px 40px 15px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #efefef;
  box-shadow: 12px 12px 48px 0px rgba(80, 80, 159, 0.04);
}

.materialui-daterange-picker-MuiPaper-root-7 {
  box-shadow: none !important;
}
.dateRangePicker div {
  box-shadow: none !important;
}

.tab-section-header {
  border: 1px solid #efefef;
  box-shadow: 12px 12px 48px 0px rgba(80, 80, 159, 0.04);
  text-align: left;
  padding: 15px 15px 10px;
  margin: 10px 10px;
  border-radius: 10px;
  h4 {
    margin: 0;
  }
  h5 {
    color: rgba(0, 0, 0, 0.5);
  }
}

.smooth-shadow {
  box-shadow: 0px 0px 17px 3px rgba(159, 159, 159, 0.5);
}

#grouped-dimensions {
  label {
    font-family: Inter;
    font-weight: 600;
    margin-left: 0 !important;
  }
}

.MuiAccordionSummary-root {
  width: 416px;
}

.data-list-form {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0px 10px 0px 6px;
  border-radius: 6px;
  cursor: default;

  .MuiFormControl-root {
    .MuiFilledInput-root {
      background-color: #ffffff !important;
    }

    input {
      font-family: Inter;
      font-weight: 600;
      font-size: 14px;
      color: #585969;
      cursor: default;
    }

    label {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      color: #abacb4;
    }
  }
}

.metric-list-form {
  width: 332px;
  padding: 1px;
  background-color: #ffffff !important;
  border: 1px solid #dddddd;
  border-radius: 6px;
  cursor: default;

  .MuiFormControl-root {
    .MuiFilledInput-root {
      background-color: #ffffff !important;
      font-size: 12px;
    }

    input {
      font-family: Inter;
      font-weight: 500;
      cursor: default;
    }

    label {
      font-family: Inter;
      font-size: 14;
      font-weight: 600;
    }
  }
}

.highlight-shadow {
  border: 2px solid #1976d2;
}

.same-padding {
  padding: 15px !important;
}

.verticle-center {
  justify-content: center;
}
.drag-handle {
  cursor: grab;
}

.lato {
  font-family: lato;
}
.inter {
  font-family: Inter;
}

.radio {
  .MuiFormControlLabel-label {
    font-family: Inter;
    color: rgba(0, 0, 0, 0.5);
  }
}

.bold {
  font-weight: bold;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #0869fb;
  border-radius: 50%;
  display: inline-block;
}
.outline-dot {
  height: 20px;
  width: 20px;
  border: 3px solid black;
  border-radius: 50%;
  display: inline-block;
}

.dashboard {
  margin: 112px 24px 24px 124px;
  // max-width:  1700px;
  box-shadow: 12px 12px 48px 0px rgba(80, 80, 159, 0.04);
  border-radius: 12px;
  // height:  calc(100vh - 120px);
  // overflow: hidden auto;
}

.top_bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 12px 12px 48px 0px rgba(80, 80, 159, 0.04);
  padding: 12px 24px;
  margin-bottom: 16px;
}

.dashboard-tabs {
  .MuiTabs-root {
    border-bottom: 1px solid #efefef !important;
    .MuiTab-labelIcon {
      // display: inline-block;
      width: auto;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    button {
      // padding: 10px 40px;
      text-transform: none;
    }
    .Mui-selected {
      font-family: Inter !important;
      color: #0869fb !important;
      font-size: 14px !important;
      font-weight: 600;
      min-height: 22px;
    }
    .Mui-disabled {
      font-family: Inter;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600;
    }
    .MuiTabs-indicator {
      // border-top-left-radius: 5px;
      // border-top-right-radius: 5px;
      height: 3px;
      background-color: #0869fb !important;
    }
  }
}

.sidebar-tabs {
  .MuiTabs-root {
    border: 1px solid #efefef !important;
    border-radius: 8px;
    padding: 8px;
    height: 50px;
    min-height: 50px;
    button {
      padding: 0px;
      min-height: 32px;
      max-height: 32px;
    }
    .Mui-selected {
      font-family: Inter !important;
      color: #ffffff !important;
      font-size: 14px !important;
      font-weight: 600;
    }
    .Mui-disabled {
      font-family: Inter;
      color: rgba(0, 0, 0, 0.5);
      font-weight: bold;
    }
    .MuiTabs-indicator {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      height: 3px;
    }
  }
}

.tab-Bar {
  // .MuiTab-root {
  .Mui-selected {
    background-color: #0869fb;
    border-radius: 6px;
    font-weight: 500;
    color: #ffffff !important;
    margin: 8px;
  }
  .MuiTabs-indicator {
    display: none;
  }
  // }
}

.newText {
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: #585969 !important;
}

.extractorHeaders {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}
.filter-list {
  width: 100%;
  border: 1px solid #eaeaec;
  border-radius: 8px;
  padding: 12px;
  cursor: default;
}

.selected-svg {
  filter: invert(29%) sepia(51%) saturate(3647%) hue-rotate(208deg)
    brightness(97%) contrast(102%);
}

.not-selected-svg {
  filter: invert(46%) sepia(9%) saturate(0%) hue-rotate(215deg) brightness(90%)
    contrast(88%);
}

.materialui-daterange-picker-makeStyles-dateRangePicker-2
  > .materialui-daterange-picker-MuiPaper-root-7
  > .materialui-daterange-picker-MuiGrid-root-35.materialui-daterange-picker-MuiGrid-container-36
  > .materialui-daterange-picker-MuiGrid-root-35:nth-child(3) {
  width: 100%;
}
