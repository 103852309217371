
.ReactModal__Overlay--after-open{
    z-index: 100 !important;
}

.input-text{
    height: 40px;
    width: 100%;
    padding: 12px 16px 12px 16px;
    border-radius: 6px;
    border: 1px solid #E0E3E5;
    margin-top: 8px;
}

.connect-account-logo{
    border : 1px solid #E0E3E5;
    width: 48px; 
    height: 48px; 
    border-radius: 10px;
    padding: 12px;
}

.connect-account-instructions li{
   font-size:  14px;
   color: #5C6674;
}