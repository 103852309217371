.report_container {
    background: #F8F9FD; 
    padding: 24px 24px 10px 124px; 
    height: 100vh;
}

.report_container .header {
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    width: 100%; 
    height: 72px; 
    background: #FFF; 
    border-radius: 12px; 
    padding: 16px 24px; 
    margin-bottom: 24px;
}

.report_container .gallery {
    background: #FFF; 
    border-radius: 12px; 
    width: 100%; 
    height: calc(100% - 100px); 
    padding: 24px;
}

.report_container .gallery .block {
    border: 1px solid #ECEEF1; 
    border-radius: 8px; 
    padding: 16px 16px 8px;
}

.report_container .heading {
    font-family: Inter;
    font-size: 20px; 
    font-weight: 600; 
    line-height: 32px;
}

.report_container .button {
    background: #0869FB; 
    color: #FFF; 
    font-family: Inter; 
    font-size: 14px; 
    font-weight: 500;
}

.flip-card {
    perspective: 1000px;
    transform-style: preserve-3d;
    transition: transform 1.2s;
}

.flip-card.flipped,
.flip-card-back {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    backface-visibility: hidden;
}